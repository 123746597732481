import React, { ElementRef } from 'react'
import clsx from 'clsx'

import {
  FacebookIcon,
  IconProps,
  InstagramIcon,
  LinkedinIcon,
  MastodonIcon,
  PinterestIcon,
  ShopifyIcon,
  StartPageIcon,
  BlueskyIcon,
  ThreadsIcon,
  TiktokIcon,
  TwitterIcon,
  WarningIcon,
  YoutubeIcon,
  GbpIcon,
} from '../icons'

import styles from './ChannelIcon.module.css'

type ChannelType =
  | 'facebook'
  | 'googlebusiness'
  | 'instagram'
  | 'linkedin'
  | 'mastodon'
  | 'pinterest'
  | 'shopify'
  | 'startPage'
  | 'tiktok'
  | 'twitter'
  | 'youtube'
  | 'x'
  | 'threads'
  | 'bluesky'

const channelIconByType = {
  facebook: FacebookIcon,
  // todo: rename!!
  googlebusiness: GbpIcon,
  instagram: InstagramIcon,
  linkedin: LinkedinIcon,
  mastodon: MastodonIcon,
  pinterest: PinterestIcon,
  shopify: ShopifyIcon,
  startPage: StartPageIcon,
  tiktok: TiktokIcon,
  twitter: TwitterIcon,
  x: TwitterIcon,
  youtube: YoutubeIcon,
  threads: ThreadsIcon,
  bluesky: BlueskyIcon,
}

type ChannelColors = {
  [key: string | ChannelType]: string
}

const channelColors: ChannelColors = {
  facebook: 'var(--color-global-channels-facebook)',
  googlebusiness: 'var(--color-global-channels-google-business-profile)',
  instagram: 'var(--color-global-channels-instagram)',
  linkedin: 'var(--color-global-channels-linkedin)',
  bluesky: 'var(--color-global-channels-bluesky)',
  mastodon: 'var(--color-global-channels-mastodon)',
  pinterest: 'var(--color-global-channels-pinterest)',
  shopify: 'var(--color-global-channels-shopify)',
  startPage: ' var(--color-global-channels-start-page)',
  tiktok: 'var(--color-global-channels-tiktok)',
  x: 'var(--color-global-channels-x)',
  twitter: 'var(--color-global-channels-x)',
  youtube: 'var(--color-global-channels-youtube)',
  threads: 'var(--color-global-channels-threads)',
}

type ChannelIconProps = Omit<IconProps, 'color'> & {
  /**
   * Type of the channel
   */
  type: ChannelType
  /**
   * Color of the icon, use `branded` for the branded color of the channel
   */
  color?: 'default' | 'subtle' | 'inverted' | 'branded'
  /**
   * Classname of the ChannelIcon
   */
  className?: string
}

const ChannelIcon = React.forwardRef<ElementRef<'svg'>, ChannelIconProps>(
  (
    { type, color = 'default', className, ...props }: ChannelIconProps,
    forwardedRef,
  ) => {
    // TODO: when we have question mark icon, warning icon should be replaced with it
    const Icon = channelIconByType[type] ?? WarningIcon

    return (
      <Icon
        style={
          {
            '--channel-icon-color': channelColors[type],
          } as React.CSSProperties
        }
        ref={forwardedRef}
        {...props}
        data-channel={type}
        color={color !== 'branded' ? color : undefined}
        className={clsx(color === 'branded' && styles.withColor, className)}
      />
    )
  },
)

ChannelIcon.displayName = 'ChannelIcon'

export { ChannelIcon, ChannelIconProps, channelColors }
export type { ChannelType }
